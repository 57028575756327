<template>
    <div>
        <div class="row m-t-6">
            <aside class="column small-12 medium-3">
                <div class="card m-b-4 p-4 br">
                    <div class="form-group form-group-compact">
                        <label class="sc-reader">{{ trans('Search') }}</label>
                        <div class="form-group-addon">
                            <input class="input__field input-small" type="text" v-bind:placeholder="trans('Search Leads')" v-model="query" v-on:keydown.enter="filterLeads">
                            <span class="form-append i-container btn btn-text i-filled-dark width-auto-min p-r-0">
                                <!-- /icons/search.svg -->
                                <svg class="icon m-l-1 i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16.7 15.3c.9-1.2 1.4-2.6 1.4-4.2 0-3.9-3.1-7.1-7-7.1S4 7.2 4 11.1c0 3.9 3.2 7.1 7.1 7.1 1.6 0 3.1-.5 4.2-1.4l3 3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3-3.1zm-5.6.8c-2.8 0-5.1-2.2-5.1-5S8.3 6 11.1 6s5.1 2.3 5.1 5.1-2.3 5-5.1 5z" class="icon"/></svg>
                            </span>
                        </div>
                    </div>
                    <div class="form-group form-group-compact">
                        <label class="sc-reader">{{ trans('Membership') }}</label>
                        <select class="input-small input__field" v-model="selectedMembership">
                            <option value="">{{ trans('All Memberships') }}</option>
                            <option v-for="membership in memberships" v-bind:value="membership.encoded_id" v-bind:key="'membership-' + membership.encoded_id">{{ membership.name }}</option>
                        </select>
                    </div>
                    <button v-on:click="filterLeads" v-bind:class="{'btn-loading': searching}" type="button" class="btn btn-primary btn--block m-b-2">
                        {{ trans('Search') }}
                        <div v-if="searching" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                    <div class="text-center">
                        <button v-on:click="resetFilter" type="button" class="btn btn-text btn-text--muted width-auto-min">{{ trans('Reset Filter') }}</button>
                    </div>
                </div>
            </aside>

            <div class="sales-content column small-12 medium-8 large-9">
                <div v-bind:class="{'table-group': paginator.lastPage > 1}">
                    <div class="table-header row row-collapse row-middle br-bottom m-b-5">
                        <div class="text-left">
                            <span v-if="loading" class="c-medium m-r-r f-s-3">{{ trans('Loading leads...') }}</span>
                            <span v-else class="c-medium m-r-3 f-s-3">{{ trans(':num leads', {'num': paginator.total}) }}</span>
                        </div>
                        <div class="column text-right">
                            <a class="btn btn-text btn-text--muted c-muted f-s-3" v-bind:href="exportUrl">{{ trans('Export leads') }}</a>
                        </div>
                    </div>
                    <div class="table-overflow-set">
                        <div v-if="loading" style="margin: 0 auto;" class="chartdonut chartdonut_animation--rotate-large chartdonut--large">
                            <svg id="svgElement" width="53" height="53"><g><circle cx="50%" cy="50%" r="23" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                        </div>
                        <div v-if=" ! loading && items.length === 0" class="text-center m-t-6">
                            <p class="c-medium f-s-3">{{ trans('No leads found.') }}</p>
                        </div>
                        <table v-if=" ! loading && items.length > 0" class="table table--layout-auto" style="margin-bottom: 54px;">
                            <thead>
                                <tr>
                                    <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Lead') }}</th>
                                    <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Membership') }}</th>
                                    <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Date') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in items" v-bind:key="item.id">
                                    <td v-bind:data-heading="trans('Lead')">
                                        <div>
                                            {{ item.email }}
                                            <span v-if="item.member_id" class="badge c-muted-dim-bg c-bright f-w-bold f-caps m-l-2">{{ trans('Member' )}}</span>
                                        </div>
                                        <div v-if="item.name && item.name.length > 0" class="c-muted f-s-1">{{ item.name }}</div>
                                    </td>
                                    <td v-bind:data-heading="trans('Membership')">{{ item.membership.name }}</td>
                                    <td v-bind:data-heading="trans('Date')">{{ formatedDate(item) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <paginator
            v-if=" ! loading"
            v-bind:to="paginator.to"
            v-bind:from="paginator.from"
            v-bind:total="paginator.total"
            v-bind:last-page="paginator.lastPage"
            v-bind:current-page="paginator.currentPage"
            v-on:paginator:switch-page="changePage"
        ></paginator>
    </div>
</template>
<script>
import Paginator from './Paginator.vue';

export default {
    components: {
        Paginator,
    },
    props: {
        leads: {
            type: [Array, Object]
        },
        memberships: {
            type: Array
        },
        total: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            default: 20
        },
        lastPage: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            items: this.leads,
            selectedMembership: "",
            paginator: {
                total: this.total,
                to: this.perPage,
                from: 1,
                lastPage: this.lastPage,
                currentPage: 1,
            },
            loading: false,
            searching: false,
            query: null,
        }
    },
    computed: {
        exportUrl() {
            return window.Kourses.baseUrl + '/tools/leads/export'
                    + '?membership=' + this.selectedMembership
                    + '&search=' + (this.query ? this.query : '');
        }
    },
    methods: {
        changePage(page) {
            this.loading = true;

            this.loadLeads(page);
        },
        filterLeads() {
            this.loading = true;
            this.searching = true;

            this.loadLeads(1);
        },
        resetFilter() {
            this.loading = true;
            this.query = "";
            this.selectedMembership = "";

            this.loadLeads(1);
        },
        loadLeads(page) {
            return window.axios.get(Kourses.apiBaseUrl + '/tools/leads', {
                params: {
                    'page': page,
                    'search': this.query,
                    'membership': this.selectedMembership,
                }
            }).then(this.showLeads);
        },
        showLeads(response) {
            this.paginator = {
                to: response.data.to,
                from: response.data.from,
                total: response.data.total,
                lastPage: response.data.last_page,
                currentPage: response.data.current_page
            };
            this.items = response.data.data;
            this.loading = false;
            this.searching = false;
        },
        formatedDate(lead) {
            let date = new Date(lead.created_at);

            return date.toLocaleTimeString(window.Kourses.locale, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
            });
        },
    },
}
</script>