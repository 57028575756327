<template>
    <div>
        <div class="row m-t-6">
            <aside class="column small-12 medium-3">
                <div class="card m-b-4 p-4 br">
                    <div class="form-group form-group-compact">
                        <label class="sc-reader">{{ trans('Search') }}</label>
                        <div class="form-group-addon">
                            <input class="input__field input-small" type="text" v-bind:placeholder="trans('Search Sales')" v-model="query" v-on:keydown.enter="filterTransactions">
                            <span class="form-append i-container btn btn-text i-filled-dark width-auto-min p-r-0">
                                <!-- /icons/search.svg -->
                                <svg class="icon m-l-1 i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16.7 15.3c.9-1.2 1.4-2.6 1.4-4.2 0-3.9-3.1-7.1-7-7.1S4 7.2 4 11.1c0 3.9 3.2 7.1 7.1 7.1 1.6 0 3.1-.5 4.2-1.4l3 3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3-3.1zm-5.6.8c-2.8 0-5.1-2.2-5.1-5S8.3 6 11.1 6s5.1 2.3 5.1 5.1-2.3 5-5.1 5z" class="icon"/></svg>
                            </span>
                        </div>
                    </div>
                    <div class="form-group form-group-compact">
                        <label class="sc-reader">{{ trans('Membership') }}</label>
                        <select class="input-small input__field" v-model="selectedMembership">
                            <option value="">{{ trans('All Memberships') }}</option>
                            <option v-for="membership in memberships" v-bind:value="membership.id" v-bind:key="membership.id">{{ membership.name }}</option>
                        </select>
                    </div>
                    <div class="form-group form-group-compact">
                        <label class="sc-reader">{{ trans('Time Period') }}</label>
                        <select class="input-small input__field" v-model="selectedTimePeriod">
                            <option value="">{{ trans('Time Period') }}</option>
                            <option value="today">{{ trans('Today') }}</option>
                            <option value="yesterday">{{ trans('Yesterday') }}</option>
                            <option value="current_week">{{ trans('Current Week') }}</option>
                            <option value="last_week">{{ trans('Last Week') }}</option>
                            <option value="current_month">{{ trans('Current Month') }}</option>
                            <option value="last_month">{{ trans('Last Month') }}</option>
                            <option value="custom">{{ trans('Custom Period') }}</option>
                        </select>
                    </div>
                    <div v-if="selectedTimePeriod === 'custom'">
                        <div class="form-group m-b-0" v-bind:class="{'has-error': dateError}">
                            <date-pick
                                v-model="dateFrom"
                                v-bind:input-attributes="{'class': 'input__field input__field--date', 'placeholder': trans('Pick a date')}"
                                v-on:input="validateDates"
                                v-bind:nextMonthCaption="datePickLabels.nextMonthCaption"
                                v-bind:prevMonthCaption="datePickLabels.prevMonthCaption"
                                v-bind:setTimeCaption="datePickLabels.setTimeCaption"
                                v-bind:weekdays="datePickLabels.weekdays"
                                v-bind:months="datePickLabels.months"
                            ></date-pick>
                        </div>
                        <div class="form-group" v-bind:class="{'has-error': dateError}">
                            <date-pick
                                v-model="dateTo"
                                v-bind:input-attributes="{'class': 'input__field input__field--date', 'placeholder': trans('Pick a date')}"
                                v-on:input="validateDates"
                                v-bind:nextMonthCaption="datePickLabels.nextMonthCaption"
                                v-bind:prevMonthCaption="datePickLabels.prevMonthCaption"
                                v-bind:setTimeCaption="datePickLabels.setTimeCaption"
                                v-bind:weekdays="datePickLabels.weekdays"
                                v-bind:months="datePickLabels.months"
                            ></date-pick>
                        </div>
                        <div v-if="dateError" class="form-help">
                            {{ trans('Invalid Date Range') }}
                        </div>
                    </div>
                    <div class="form-group form-group-compact">
                        <label class="sc-reader">{{ trans('Status') }}</label>
                        <select class="input-small input__field" v-model="selectedStatus">
                            <option value="">{{ trans('All Statuses') }}</option>
                            <option value="active">{{ trans('Paid') }}</option>
                            <option value="past_due">{{ trans('Past Due') }}</option>
                            <option value="cancelled">{{ trans('Cancelled') }}</option>
                            <option value="partially_refunded">{{ trans('Partially Refunded') }}</option>
                            <option value="refunded">{{ trans('Refunded') }}</option>
                        </select>
                    </div>
                    <button v-on:click="filterTransactions" v-bind:class="{'btn-loading': searching}" type="button" class="btn btn-primary btn--block m-b-2">
                        {{ trans('Search') }}
                        <div v-if="searching" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                    <div class="text-center">
                        <button v-on:click="resetFilter" type="button" class="btn btn-text btn-text--muted width-auto-min">{{ trans('Reset Filter') }}</button>
                    </div>
                </div>
                <a class="btn btn-text btn-text--muted c-muted f-s-3 p-t-0" href="#tax-reports" v-on:click.prevent="showTaxReportsModal">{{ trans('Need EU tax reports?') }}</a>
            </aside>

            <div class="sales-content column small-12 medium-8 large-9">
                <div v-bind:class="{'table-group': paginator.lastPage > 1}">
                    <div class="table-header row row-collapse row-middle br-bottom m-b-5">
                        <div class="text-left">
                            <span v-if="loading" class="c-medium m-r-r f-s-3">{{ trans('Loading transactions...') }}</span>
                            <span v-else class="c-medium m-r-3 f-s-3">{{ trans(':num transactions', {'num': paginator.total}) }}</span>
                        </div>
                        <div class="column text-left">
                            <button v-on:click="switchEnvironment" type="button" class="f-s-3 c-accent h-c-accent cu-p m-r-3">
                                <span class="i-container i-filled-accent">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="injected-svg icon inject-svg i-size-small"><g transform="translate(4 6)" class="icon"><path d="M8 12c4.707 0 7.744-5.284 7.871-5.508a1 1 0 0 0 .001-.98C15.746 5.287 12.731 0 8 0 3.245 0 .251 5.289.126 5.514a.998.998 0 0 0 .002.975C.254 6.713 3.269 12 8 12zM8 2c2.839 0 5.036 2.835 5.818 4-.784 1.166-2.981 4-5.818 4-2.841 0-5.038-2.838-5.819-4.001C2.958 4.835 5.146 2 8 2z"></path><circle cx="8" cy="6" r="2"></circle></g></svg>
                                </span>
                                <span v-if="selectedEnvironment === 'live'" class="inline">{{ trans('View Test Data') }}</span>
                                <span v-else class="inline">{{ trans('View Live Data') }}</span>
                            </button>
                        </div>
                        <div class="column text-right">
                            <a class="btn btn-text btn-text--muted c-muted f-s-3" v-bind:href="exportUrl">{{ trans('Export sales history') }}</a>
                        </div>
                    </div>
                    <div class="table-overflow-set">
                        <div v-if="loading" style="margin: 0 auto;" class="chartdonut chartdonut_animation--rotate-large chartdonut--large">
                            <svg id="svgElement" width="53" height="53"><g><circle cx="50%" cy="50%" r="23" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                        </div>
                        <div v-if=" ! loading && items.length === 0" class="text-center m-t-6">
                            <p v-if="selectedEnvironment === 'live'" class="c-medium f-s-3">{{ trans('No sales found.  If you\'re looking for "test" sales - click View test data above') }}</p>
                            <p v-else class="c-medium f-s-3">{{ trans('No sales found.') }}</p>
                        </div>
                        <table v-if=" ! loading && items.length > 0" class="table" style="margin-bottom: 54px;">
                            <thead>
                                <tr>
                                    <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3" style="width: 120px;">{{ trans('Date') }}</th>
                                    <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Membership') }}</th>
                                    <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Member') }}</th>
                                    <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3" style="width: 100px;">{{ trans('Status') }}</th>
                                    <th class="text-right br-bottom br-c--light c-medium-tint f-w-regular f-s-3" width="120px">{{ trans('Order Total') }}</th>
                                    <th v-if="taxEnabled" class="text-right br-bottom br-c--light c-medium-tint f-w-regular f-s-3" width="100px">{{ trans('Tax') }}</th>
                                    <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3" width="72px">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in items" v-bind:key="item.id">
                                    <td v-bind:data-heading="trans('Date')">{{ purchaseDate(item) }}</td>
                                    <td v-bind:data-heading="trans('Membership')">{{ item.membership_name }}</td>
                                    <td v-bind:data-heading="trans('Member')">{{ item.member_full_name }}</td>
                                    <td v-bind:data-heading="trans('Status')">{{ item.status_label }}</td>
                                    <td class="text-right" v-bind:data-heading="trans('Order Total')">
                                        {{ item.formatted_price_including_tax }}
                                        <span v-if="item.coupon" class="block f-s-1 c-medium">{{ trans(':discount off', {'discount': item.coupon.discount}) }}</span>
                                    </td>
                                    <td v-if="taxEnabled" class="text-right" v-bind:data-heading="trans('Tax')">{{ item.formatted_tax }}</td>
                                    <td class="text-right">
                                        <a class="i-container i-filled-accent btn btn-text width-auto-min p-0 m-r-0" v-bind:href="item.links.show">
                                            <!-- /icons/right.svg -->
                                            <svg class="icon i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M14.671 12l-6.424 7.341 1.506 1.318L17.329 12 9.753 3.341 8.247 4.66z" class="icon"/></svg>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <paginator
            v-if=" ! loading"
            v-bind:to="paginator.to"
            v-bind:from="paginator.from"
            v-bind:total="paginator.total"
            v-bind:last-page="paginator.lastPage"
            v-bind:current-page="paginator.currentPage"
            v-on:paginator:switch-page="changePage"
        ></paginator>
        <tax-reports-modal ref="taxReportsModal"></tax-reports-modal>
    </div>
</template>
<script>
import Paginator from './Paginator.vue';
import DatePick from 'vue-date-pick';
import DatePickLocalizationMixin from './fields/DatePickLocalizationMixin.vue';
import TaxReportsModal from './modals/TaxReportsModal.vue';

export default {
    components: {
        Paginator,
        DatePick,
        TaxReportsModal,
    },
    mixins: [
        DatePickLocalizationMixin
    ],
    props: {
        transactions: {
            type: [Array, Object]
        },
        memberships: {
            type: Array
        },
        taxEnabled: {
            type: Boolean,
            default: false
        },
        total: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            default: 20
        },
        lastPage: {
            type: Number,
            default: 1
        },
        environment: {
            type: String,
            default: 'live',
        },
    },
    data() {
        return {
            items: this.transactions,
            selectedMembership: "",
            selectedTimePeriod: "",
            selectedStatus: "",
            dateFrom: null,
            dateTo: null,
            dateError: false,
            paginator: {
                total: this.total,
                to: this.perPage,
                from: 1,
                lastPage: this.lastPage,
                currentPage: 1,
            },
            loading: false,
            searching: false,
            query: null,
            selectedEnvironment: this.environment,
        }
    },
    computed: {
        exportUrl() {
            return window.Kourses.activeBaseUrl + '/sales/export'
                    + '?membership=' + this.selectedMembership + '&status=' + this.selectedStatus
                    + '&time_period=' + this.selectedTimePeriod + '&date_to=' + (this.dateTo ? this.dateTo : '') + '&date_from=' + (this.dateFrom ? this.dateFrom : '')
                    + '&environment=' + this.selectedEnvironment
                    + '&query=' + (this.query ? this.query : '');
        }
    },
    methods: {
        switchEnvironment() {
            this.selectedEnvironment = this.selectedEnvironment === 'live' ? 'test' : 'live';

            this.loading = true;

            this.loadTransactions(1);
        },
        purchaseDate(transaction) {
            let purchaseDate = new Date(transaction.created_at);

            return purchaseDate.toLocaleDateString(window.Kourses.locale, {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
            });
        },
        changePage(page) {
            this.loading = true;

            this.loadTransactions(page);
        },
        filterTransactions() {
            this.loading = true;
            this.searching = true;

            this.loadTransactions(1);
        },
        resetFilter() {
            this.loading = true;
            this.query = "";
            this.selectedStatus = "";
            this.selectedMembership = "";
            this.selectedTimePeriod = "";
            this.dateError = false;
            this.dateFrom = null;
            this.dateTo = null;

            this.loadTransactions(1);
        },
        loadTransactions(page) {
            return window.axios.get(Kourses.apiBaseUrl + '/transactions', {
                params: {
                    'page': page,
                    'query': this.query,
                    'status': this.selectedStatus,
                    'membership': this.selectedMembership,
                    'date_to': this.dateTo,
                    'date_from': this.dateFrom,
                    'time_period': this.selectedTimePeriod,
                    'environment': this.selectedEnvironment,
                }
            }).then(this.showTransactions);
        },
        showTransactions(response) {
            this.paginator = {
                to: response.data.meta.to,
                from: response.data.meta.from,
                total: response.data.meta.total,
                lastPage: response.data.meta.last_page,
                currentPage: response.data.meta.current_page
            };
            this.items = response.data.data;
            this.loading = false;
            this.searching = false;
        },
        validateDates() {
            if (null === this.dateFrom || null === this.dateTo) {
                this.dateError = false;
            } else {
                let from = new Date(this.dateFrom);
                let to = new Date(this.dateTo);

                if (from > to) {
                    this.dateError = true;
                } else {
                    this.dateError = false;
                }
            }
        },
        showTaxReportsModal() {
            this.$refs.taxReportsModal.show = true;
        },
    }
}
</script>