<template>
    <div>
        <div class="row m-t-6">
            <aside class="column small-12 medium-3">
                <div class="card m-b-4 p-4 br">
                    <div class="form-group form-group-compact">
                        <label class="sc-reader">{{ trans('Search') }}</label>
                        <div class="form-group-addon">
                            <input class="input__field input-small" type="text" v-bind:placeholder="trans('Search records')" v-model="query" v-on:keydown.enter="filterRecords">
                            <span class="form-append i-container btn btn-text i-filled-dark width-auto-min p-r-0">
                                <!-- /icons/search.svg -->
                                <svg class="icon m-l-1 i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16.7 15.3c.9-1.2 1.4-2.6 1.4-4.2 0-3.9-3.1-7.1-7-7.1S4 7.2 4 11.1c0 3.9 3.2 7.1 7.1 7.1 1.6 0 3.1-.5 4.2-1.4l3 3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3-3.1zm-5.6.8c-2.8 0-5.1-2.2-5.1-5S8.3 6 11.1 6s5.1 2.3 5.1 5.1-2.3 5-5.1 5z" class="icon"/></svg>
                            </span>
                        </div>
                    </div>
                    <button v-on:click="filterRecords" v-bind:class="{'btn-loading': searching}" type="button" class="btn btn-primary btn--block m-b-2">
                        {{ trans('Search') }}
                        <div v-if="searching" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                    <div class="text-center">
                        <button v-on:click="resetFilter" type="button" class="btn btn-text btn-text--muted width-auto-min">{{ trans('Reset Filter') }}</button>
                    </div>
                </div>
            </aside>

            <div class="sales-content column small-12 medium-8 large-9">
                <div v-bind:class="{'table-group': paginator.lastPage > 1}">
                    <div class="table-overflow-set">
                        <div v-if="loading" style="margin: 0 auto;" class="chartdonut chartdonut_animation--rotate-large chartdonut--large">
                            <svg id="svgElement" width="53" height="53"><g><circle cx="50%" cy="50%" r="23" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                        </div>
                        <div v-if=" ! loading && items.length === 0" class="text-center m-t-6">
                            <p class="c-medium f-s-3">{{ trans('No records found.') }}</p>
                        </div>
                        <table v-if=" ! loading && items.length > 0" class="table" style="margin-bottom: 54px;">
                            <thead>
                                <tr>
                                    <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Type') }}</th>
                                    <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3">{{ trans('Value') }}</th>
                                    <th class="br-bottom br-c--light c-medium-tint f-w-regular f-s-3" width="72px">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in items" v-bind:key="item.encoded_id">
                                    <td v-bind:data-heading="trans('Type')">{{ item.label }}</td>
                                    <td v-bind:data-heading="trans('Value')">{{ item.value }}</td>
                                    <td class="text-right">
                                        <button type="button" class="i-container i-filled-accent btn btn-text width-auto-min p-0 m-r-0" v-on:click="deleteItem(index, item)">
                                            <span class="tooltip animate">
                                                <span class="i-container i-filled-theme">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="icon i-size-tiny inline"><g fill="#6F6FFF" class="icon"><path d="M14.5 3.38h-3.512C10.923 1.782 9.613.5 8 .5S5.077 1.782 5.012 3.38H1.5a1 1 0 1 0 0 2h13a1 1 0 1 0 0-2zM8 2.5a.99.99 0 0 1 .976.88H7.024A.99.99 0 0 1 8 2.5zm5.499 3.507a1.01 1.01 0 0 0-1.104.884L11.604 14H4.396l-.79-7.109a1 1 0 0 0-1.989.22l.889 7.999A1 1 0 0 0 3.5 16h9a1 1 0 0 0 .994-.89l.889-7.999a1 1 0 0 0-.884-1.104z"></path><path d="M7.5 11.5v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm3 0v-4a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0z"></path></g></svg>
                                                </span>
                                                <span class="tooltip-content tooltip-item tooltip--top">{{ trans('Delete') }}</span>
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <paginator
            v-if=" ! loading"
            v-bind:to="paginator.to"
            v-bind:from="paginator.from"
            v-bind:total="paginator.total"
            v-bind:last-page="paginator.lastPage"
            v-bind:current-page="paginator.currentPage"
            v-on:paginator:switch-page="changePage"
        ></paginator>
    </div>
</template>
<script>
import { blocklistDeleteUrl, blocklistIndexUrl } from '../routes.js';
import Paginator from './Paginator.vue';
import { EventBus } from '../event-bus.js';

export default {
    components: {
        Paginator,
    },
    props: {
        records: {
            type: [Array, Object]
        },
        total: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            default: 20
        },
        lastPage: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            items: this.records,
            paginator: {
                total: this.total,
                to: this.perPage,
                from: 1,
                lastPage: this.lastPage,
                currentPage: 1,
            },
            loading: false,
            searching: false,
            query: null,
        }
    },
    methods: {
        changePage(page) {
            this.loading = true;

            this.loadRecords(page);
        },
        filterRecords() {
            this.loading = true;
            this.searching = true;

            this.loadRecords(1);
        },
        resetFilter() {
            this.loading = true;
            this.query = "";

            this.loadRecords(1);
        },
        loadRecords(page) {
            return window.axios.get(blocklistIndexUrl(), {
                params: {
                    'page': page,
                    'query': this.query,
                }
            }).then(this.showRecords);
        },
        showRecords(response) {
            this.paginator = {
                to: response.data.meta.to,
                from: response.data.meta.from,
                total: response.data.meta.total,
                lastPage: response.data.meta.last_page,
                currentPage: response.data.meta.current_page
            };
            this.items = response.data.data;
            this.loading = false;
            this.searching = false;
        },
        deleteItem(index, item) {
            this.$dialog
                .confirm(this.trans("If you delete the record, this visitor can complete the checkout."), {loader: true})
                .then((dialog) => {
                    window.axios.delete(blocklistDeleteUrl(item)).then(() => {
                        this.items.splice(index, 1);
                        dialog.close();
                    });
                });
        },
    },
    created() {
        let $vm = this;

        EventBus.$on('blocklist:added', (record) => {
            $vm.items.push(record);
        });
    },
}
</script>