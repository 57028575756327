<template>
    <modal v-bind:show.sync="show" ref="modal" class="text-left">
        <div slot="header">
            <h3 class="panel-title f-s-5">{{ trans('Generate Tax Report') }}</h3>
        </div>

        <div class="row row-collapse row-collapse-indent">
            <div class="columns">
                <div class="form-group" v-bind:class="{'has-error': errors.has('date_from')}">
                    <date-pick
                        v-model="dateFrom"
                        v-bind:input-attributes="{'class': 'input__field input__field--date', 'placeholder': trans('Pick a start date')}"
                        v-on:input="validateDates"
                        v-bind:nextMonthCaption="datePickLabels.nextMonthCaption"
                        v-bind:prevMonthCaption="datePickLabels.prevMonthCaption"
                        v-bind:setTimeCaption="datePickLabels.setTimeCaption"
                        v-bind:weekdays="datePickLabels.weekdays"
                        v-bind:months="datePickLabels.months"
                        v-bind:isDateDisabled="isDateDisabled"
                    ></date-pick>
                </div>
                <div v-if="errors.has('date_from')" class="form-help">{{ errors.get('date_from') }}</div>

                <div class="form-group" v-bind:class="{'has-error': errors.has('date_to')}">
                    <date-pick
                        v-model="dateTo"
                        v-bind:input-attributes="{'class': 'input__field input__field--date', 'placeholder': trans('Pick an end date')}"
                        v-on:input="validateDates"
                        v-bind:nextMonthCaption="datePickLabels.nextMonthCaption"
                        v-bind:prevMonthCaption="datePickLabels.prevMonthCaption"
                        v-bind:setTimeCaption="datePickLabels.setTimeCaption"
                        v-bind:weekdays="datePickLabels.weekdays"
                        v-bind:months="datePickLabels.months"
                        v-bind:isDateDisabled="isDateDisabled"
                    ></date-pick>
                </div>
                <div v-if="errors.has('date_to')" class="form-help">{{ errors.get('date_to') }}</div>

                <div class="form-group" v-bind:class="{'has-error': errors.has('type')}">
                    <label>
                        {{ trans('Type') }}
                        <select v-model="type">
                            <option v-bind:value="null">{{ trans('Select report type') }}</option>
                            <option value="vat_oss">{{ trans('VAT OSS') }}</option>
                            <option value="reverse_charge">{{ trans('Reverse Charge') }}</option>
                        </select>
                    </label>
                </div>
                <div v-if="errors.has('type')" class="form-help">{{ errors.get('type') }}</div>
            </div>
        </div>

        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" class="btn btn-primary m-r-3" v-on:click="generateReportUrl">{{ trans('Generate Report') }}</button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Modal from './Modal.vue';
import Errors from '../../errors.js';
import DatePick from 'vue-date-pick';
import DatePickLocalizationMixin from '../fields/DatePickLocalizationMixin.vue';
import { taxReportBaseUrl } from '../../routes.js';

export default {
    components: {
        Modal,
        DatePick
    },
    mixins: [
        DatePickLocalizationMixin
    ],
    data() {
        return {
            show: false,
            type: null,
            dateTo: null,
            dateFrom: null,
            errors: new Errors(),
        }
    },
    methods: {
        close() {
            this.show = false;
            this.dateTo = null;
            this.dateFrom = null;
            this.errors.clear();
        },
        generateReportUrl() {
            this.errors.clear();

            this.validateDates();

            if (this.type === null) {
                this.errors.set('type', this.trans('Please select report type.'));
            }

            if (this.dateTo === null) {
                this.errors.set('date_to', this.trans('Please select end date.'));
            }

            if (this.dateFrom === null) {
                this.errors.set('date_from', this.trans('Please select start date.'));
            }

            if (this.errors.any()) {
                return;
            }

            let url = `${taxReportBaseUrl()}?type=${this.type}&start_date=${this.dateFrom}&end_date=${this.dateTo}`;

            this.close();

            window.location.href = url;
        },
        validateDates() {
            if (null === this.dateFrom || null === this.dateTo) {
                this.errors.clear('date_to');
                this.errors.clear('date_from');
            } else {
                let from = new Date(this.dateFrom);
                let to = new Date(this.dateTo);

                if (from > to) {
                    this.errors.set('date_from', this.trans('Start date must be before end date.'));
                }
            }
        },
        isDateDisabled(date) {
            const today = new Date();
            const currentQuarter = Math.floor((today.getMonth() + 3) / 3);
            // Two quarters ago
            const startOfQuarter = new Date(today.getFullYear(), (currentQuarter - 3) * 3, 1);

            return date < startOfQuarter;
        },
    }
}
</script>