<template>
    <div>
        <integrations-membership-settings
            v-if="integration.provider"
            v-bind:active-integration="integration"
            v-bind:active-lists="lists"
            v-bind:active-tags="tags"
            v-bind:membership-integration="membershipIntegration"
            v-bind:active-extra-fields="extraFields"
            v-bind:errors="errors"
            v-bind:field-name-prefix="membershipIntegration.action"
            v-on:remove:active="removeActiveIntegration"
        ></integrations-membership-settings>

        <div v-else>
            <div class="slat slat-empty slat-empty--large">
                <div class="row-center row m-b-4 row-middle">
                    <div class="div m-r-4">
                        <img v-bind:src="emptyImageUrl" width="300" />
                    </div>
                    <div class="column p-r-8">
                        <h4 class="c-primary card-title f-s-6 f-w-regular m-b-2">{{ trans('No service added yet') }}</h4>
                        <p class="f-s-4 m-b-5 c-medium-tint">{{ trans('Add MailChimp, Active Campaign and other services') }}</p>
                        <a v-on:click="showModal" class="btn btn-primary">{{ trans('Add Service') }}</a>
                    </div>
                </div>
            </div>
        </div>

        <integrations-modal
            v-bind:integrations="integrations"
            v-bind:active-integration="integration"
            v-bind:action="membershipIntegration.action"
            v-bind:ref="membershipIntegration.action + '-modal-add-service'"
            v-on:close="closeModal"
            v-on:connect:default="connectIntegration"
            v-on:connect:oauth="connectIntegration"
        ></integrations-modal>
    </div>
</template>
<script>
import Errors from '../../../errors';
import { membershipIntegrationsLink } from '../../../routes';
import IntegrationsMembershipSettings from './IntegrationsMembershipSettings.vue';
import IntegrationsModal from './IntegrationsModal.vue';

export default {
    components: {
        IntegrationsMembershipSettings,
        IntegrationsModal
    },
    props: {
        membership: {
            type: Object
        },
        activeIntegration: {
            type: Object
        },
        integrations: {
            type: Array
        },
        activeLists: {
            type: Array
        },
        integrationHasTags: {
            type: Boolean
        },
        integrationHasExtraFields: {
            type: Boolean
        },
        activeExtraFields: {
            type: [Array, Object]
        },
        activeTags: {
            type: [Array, Object]
        },
        isSomethingLoading: {
            type: Boolean
        },
        validationErrors: {
            type: [Array, Object]
        },
        membershipIntegration: {
            type: Object
        },
    },
    data() {
        return {
            integration:   this.activeIntegration,
            lists:         this.activeLists,
            hasTags:       this.integrationHasTags,
            tags:          this.activeTags,
            listId:        this.membershipIntegration.listId,
            membershipTags:   this.membershipIntegration.tags,
            isLoading:     this.isSomethingLoading,

            hasExtraFields: this.integrationHasExtraFields,
            extraFields: this.activeExtraFields,

            emptyImageUrl: Kourses.baseUrl + '/img/integrations-emailmarketing.png',
            errors: new Errors(this.validationErrors),
        };
    },
    methods: {
        showModal() {
            this.modal.show = true;
        },
        closeModal() {
            this.modal.reset();
            this.modal.show = false;
        },
        connectIntegration(integration) {
            // Select integration and close modal
            this.loading(true);
            this.hasTags = false;
            this.hasExtraFields = false;
            this.integration   = integration;
            let connectionData = Object.assign({}, integration.connection);
            this.closeModal();

            // Store the choice and load the integration lists
            window.axios
                .post(membershipIntegrationsLink(this.membership), {
                    'provider':    integration.provider,
                    'integration': integration.uid,
                    'connection':  connectionData,
                }).then((response) => {
                    this.hasTags = integration.config.has_tags;

                    if (this.hasTags) {
                        this.tags = response.data.tags;
                    } else {
                        this.tags = null;
                    }

                    this.hasExtraFields = integration.config.has_extra_fields;

                    // We need to update the integration UID in order to use
                    // it if we want to connect list immediately
                    this.integration.uid = response.data.uid;

                    this.lists = response.data.lists.slice();
                    this.loading(false);
                }).catch(() => {
                    this.$toast.danger(this.trans("Error when connecting the provider!"));

                    if (response.data && response.data.message) {
                        this.$toast.danger(response.data.message);
                    }

                    this.loading(false);
                });
        },
        removeActiveIntegration() {
            this.$dialog.confirm(this.trans('Do you really want to disconnect the integration?'))
                .then(() => {
                    // Clear out integration and disconnect
                    this.loading(true);
                    this.lists = [];
                    this.listId = '';
                    this.integration = {};
                    this.membershipTags = [];
                    this.errors.clear();

                    // Remove integration from query string
                    window.history.replaceState(null, '', window.location.href.split('?')[0]);

                    window.axios
                        .post(membershipIntegrationsLink(this.membership), {'_method': 'delete', 'action': this.membershipIntegration.action})
                        .then(() => {
                            this.$toast.success(this.trans("Disconnected!"));
                            this.loading(false);
                        }).catch(() => {
                            this.$toast.danger(this.trans("Error!"));
                            this.loading(false);
                        });
                });
        },

        /**
         * Toggle the loading animation
         * @param state
         */
        loading(state) {
            this.isLoading = !!state;
        }
    },
    mounted() {
        // Define the modal
        this.modal = this.$refs[this.membershipIntegration.action + '-modal-add-service'];
    },
}
</script>
