<template>
    <div>
        <div class="form-group m-t-2">
            <label>{{ trans('Membership Name Field') }}</label>
            <select v-bind:name="fieldNamePrefix + '[data][extra_fields][membership_name]'" class="input__field" v-model="membershipName">
                <option v-bind:value="null">---</option>
                <option v-for="field in availableExtraFields" v-bind:key="field.id" v-bind:value="field.id">{{ field.label }}</option>
            </select>
        </div>
        <div class="form-group m-t-2">
            <label>{{ trans('Checkout URL Field') }}</label>
            <select v-bind:name="fieldNamePrefix + '[data][extra_fields][checkout_url]'" class="input__field" v-model="checkoutUrl">
                <option v-bind:value="null">---</option>
                <option v-for="field in availableExtraFields" v-bind:key="field.id" v-bind:value="field.id">{{ field.label }}</option>
            </select>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        integration: {
            type: Object,
        },
        selectedExtraFields: {
            type: [Array, Object],
        },
        availableExtraFields: {
            type: [Array, Object],
        },
        errors: {
            type: [Array, Object]
        },
        fieldNamePrefix: {
            type: String
        },
    },
    data() {
        return {
            membershipName: this.selectedExtraFields.membershipName,
            checkoutUrl: this.selectedExtraFields.checkoutUrl,
        }
    }
}
</script>